:root{
    --primary-color: #575bea;
    --primary-color-hover: #4c4fb5;

    --side-menu-color: #575bea;
    --side-menu-active-background: #9da0f5;

    --background-color: #f0f1ff;
    
    --top-menu-background: #f0f1ff;

    --layout-card-background: #ffffff;
    --layout-card-active: #575bea;

    --card1: #2c3e50;
    --card1-text: white;

    --card2: #2c3e50;
    --card2-text: white;

    --card3: #2c3e50;
    --card3-text: white;    

    --card4: #2c3e50;
    --card4-text: white;    

    --card5: #2c3e50;
    --card5-text: white;    

    --card6: #2c3e50;
    --card6-text: white;    
}

html {
    font-size: 14px;
    background-color: var(--background-color);
}
a{ text-decoration: none; }
a:hover{ text-decoration: none; }
@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}
.disabled{
    cursor: default!important;
}
.disabled_grayscale{
    filter: grayscale(1);
    opacity: 0.5;
}
.circle_loader {
    border: 5px solid #f3f3f3; 
    border-top: 5px solid var(--primary-color);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: circle_loader_spin 2s linear infinite;
    margin: auto;
}

@keyframes circle_loader_spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.absolute_center_container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
}  

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.no-outline:focus, .no-outline:active:focus, .no-outline.active:focus,
.no-outline.focus, .no-outline:active.focus, .no-outline.active.focus {
    outline: none !important;
    box-shadow: none !important;
}

.modal-content{
    border-radius: 10px;
}

.layout_card{
    border-radius: 10px;
    background-color: var(--layout-card-background);
    padding: 15px;    
    margin-bottom: 10px;
}

.layout_card_padded{
    padding: 25px;

    .pagination{
        margin-bottom: 0px!important;
    }
}

.layout_card_25bottom{
    transition: 0.5s all;
    margin-bottom: 25px;
}

.custom-btn-primary{
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: white;
    transition: 0.5s;
}

.custom-btn-primary:hover{
    background-color: var(--primary-color-hover);
    border-color: var(--primary-color-hover);
    color: white;
}

.redirectNavigationLink{
    color: var(--primary-color);
    text-decoration: none;
}

.redirectNavigationLink:hover{
    color: var(--primary-color-hover);
    text-decoration: none;
}

.rounded_input{
    border-radius: 10px!important;
}

.layout_card1{
    cursor: pointer;
    color: var(--card1-text);
    background-color: var(--card1);
    transition: 0.5s;
    overflow:hidden; 
    white-space:nowrap; 
    text-overflow: ellipsis;
    margin-bottom: 25px;    

    label{        
        color: var(--card1-text);
        opacity: 0.9;
        cursor: pointer;
    }

    .avatar_container{
        background-color: var(--side-menu-color);
        color: var(--card1);
        border-radius: 50%;
        padding: 10px;
        margin: 10px;
        margin-bottom: 15px;
    }
}
.layout_card1:hover{
    opacity: 0.9;
}

.layout_card2
{
    cursor: pointer;
    color: var(--card2-text);
    background-color: var(--card2);
    transition: 0.5s;
    overflow:hidden; 
    white-space:nowrap; 
    text-overflow: ellipsis;
    margin-bottom: 25px;    

    label{        
        color: var(--card2-text);
        opacity: 0.9;
        cursor: pointer;
    }
}
.layout_card2:hover{
    opacity: 0.9;
}

.layout_card3
{
    cursor: pointer;
    color: var(--card3-text);
    background-color: var(--card3);
    transition: 0.5s;
    overflow:hidden; 
    white-space:nowrap; 
    text-overflow: ellipsis;
    margin-bottom: 25px;    

    label{        
        color: var(--card3-text);
        opacity: 0.9;
        cursor: pointer;
    }
}
.layout_card3:hover{
    opacity: 0.9;
}

.layout_card4
{
    cursor: pointer;
    color: var(--card4-text);
    background-color: var(--card4);
    transition: 0.5s;
    overflow:hidden; 
    white-space:nowrap; 
    text-overflow: ellipsis;
    margin-bottom: 25px;    

    label{        
        color: var(--card4-text);
        opacity: 0.9;
        cursor: pointer;
    }
}
.layout_card4:hover{
    opacity: 0.9;
}

.layout_card5
{
    cursor: pointer;
    color: var(--card5-text);
    background-color: var(--card5);
    transition: 0.5s;
    overflow:hidden; 
    white-space:nowrap; 
    text-overflow: ellipsis;
    margin-bottom: 25px;    

    label{        
        color: var(--card5-text);
        opacity: 0.9;
        cursor: pointer;
    }
}
.layout_card5:hover{
    opacity: 0.9;
}

.layout_card6
{
    cursor: pointer;
    color: var(--card6-text);
    background-color: var(--card6);
    transition: 0.5s;
    overflow:hidden; 
    white-space:nowrap; 
    text-overflow: ellipsis;
    margin-bottom: 25px;    

    label{        
        color: var(--card6-text);
        opacity: 0.9;
        cursor: pointer;
    }
}
.layout_card6:hover{
    opacity: 0.9;
}








.layout_card_primary{
    background-color: var(--primary-color);
    color: white;
}
.layout_card_active{
    background-color: var(--layout-card-active);
    color: white;

    label{
        color: white;
    }
}

.layout_card_white_accent{
    background-color: white;
    color: var(--primary-color);

    label{
        color: var(--primary-color);
        opacity: 0.9;
    }
}

.layout_card_no_click{    
    color: white;
    background-color: #2c3e50;
    transition: 0.5s;
    overflow:hidden; 
    white-space:nowrap; 
    text-overflow: ellipsis;
    margin-bottom: 25px;    

    label{        
        color: #ACB7C2;
        transition: 0.5s all;
    }
    label:hover{
        color: #2980b9;
    }

    .label_selected{
        color: #2980b9;
    }
}



.medical_registration_steps_container{
    display: flex;
    justify-content: space-around;

    .step{
        height: 30px;
        width: 30px;
        color: white;
        background-color: #2c3e50;
        border-radius: 50%;
        text-align: center;
        padding-top: 3px;
        opacity: 0.5;        
        transition: 0.5s;
    }
    .step:hover{
        opacity: 0.7;
    }

    .step_active{
        background-color: var(--primary-color);
        opacity: 1;
    }
    .step_active:hover{
        background-color: var(--primary-color-hover);
        opacity: 1;
    }

    .step_done{
        background-color: #27ae60;
        opacity: 1;
    }
}





.no-suggestions {
    color: #999;
    padding: 0.5rem;
}

.suggestions {
    border: 1px solid #999;
    border-radius: 10px;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 200px;
    overflow-y: auto;
    padding-left: 0;
    width: calc(300px + 1rem);
}

.suggestions li {
    padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
    background-color: #2980b9;
    color: white;
    cursor: pointer;
    font-weight: 700;
}

.suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
}

.pagination{
    .page-item{
        cursor: pointer;
    }
}


a { color: var(--primary-color!important) }
a​:hover { color: var(--primary-color-hover!important) }
.page-link { color: var(--primary-color-hover!important) }
.page-link​:hover { color: var(--primary-color-hover!important) }

.tableLastRight {
    td:last-child {
        text-align: right;
    }

    th:last-child {
        text-align: right;
    }
}





.employeeDataLayoutCard{
    background-color: white;
    color: #2c3e50;

    label{        
        color: #2c3e50;
    }

    .avatar_container{
        background-color: #2c3e50;
        color: white;
        border-radius: 50%;
        padding: 10px;
        margin: 10px;
        margin-bottom: 15px;
    }    
}

.color_tag{
    margin-top: -16px;
    right: 35px;
    position: absolute;        
    height: 35px;
    width: 35px;        
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;        
}

.color_tag2{
    margin-top: -15px;
    right: 0px;
    position: absolute;        
    height: 100%;
    width: 20px;        
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px; 
    transition: 0.5s all;
}

.apt{
    background-color: #16a085;
}

.apt_cond{
    background-color: #e67e22;
}

.blue{
    background-color: #3498db;
}

.inapt_temp{
    background-color: #8e44ad;
}

.inapt{
    background-color: #c0392b;
}

.unknown{
    background-color: #ecf0f1;
}









.green_color{
    background-color: #16a085;
}

.card_green{
    background-color: #16a085;
}
.card_blue{
    background-color: #3498db;
    color: white;
    .color_tag2{
        background-color: white!important;
    }
}
.card_primary{
    background-color: var(--primary-color);
}
.color_bar{
    background-color: #16a085;  
    position: relative;
    margin-right: -10px;
    right: 0;
    padding: 10px;
    margin-top: -10px;
    border-radius: 10px;
    color: white;
}
.color_red{
    background-color: #e74c3c;
}
.color_orange{
    background-color: #e67e22;
}
.color_purple{
    background-color: #8e44ad;
}

.nav-link{
    cursor: pointer;
}

.cursor_pointer{
    cursor: pointer;
}

.color_primary{
    color: var(--primary-color);
}

.danger_card{
    background-color: #e74c3c;
    color: white;
    .color_tag2{
        background-color: white!important;
    }
}
.warning_card{
    background-color: #e67e22;
    color: white;
    .color_tag2{
        background-color: white!important;
    }
}
.card_purple{
    background-color: #8e44ad;
    color: white;
    .color_tag2{
        background-color: white!important;
    }
}

.success_card{
    background-color: #2ecc71;
    color: white;
}


.active_side_menu
{
    background-color: var(--side-menu-active-background);
    color: white;
}

.custom-btn-side-menu{
    background-color: var(--side-menu-color);
    border-color: var(--side-menu-active-background);
    color: white;
    transition: 0.5s;
}

.custom-btn-side-menu:hover{
    background-color: var(--side-menu-active-background);
    border-color: var(--side-menu-active-background);
    color: white;
}

.page-link{
    color: var(--primary-color);
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
  background-color: var(--primary-color-hover) !important;
  border-color: var(--primary-color-hover) !important;
  color: white !important;
}

.btn-primary {
  color: white !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.bkg_clr_table_row{
    background-color: var(--background-color);
}

.role_option{
    width: 100%;
    padding: 10px;

    .role_option_content{
        width: 100%;
        background-color: var(--background-color);
        border-radius: 30px;
        text-align: center;
        padding-top: 30px;
        padding-bottom: 10px;
        transition: 0.5s all;
        cursor: pointer;
        svg{
            transition: 0.5s all;
            margin-bottom: 10px;
        }
    }

    .role_option_content:hover{
        color: var(--primary-color);
        svg{
            outline: var(--primary-color);
            fill: var(--primary-color);
            color: var(--primary-color);
            stroke: var(--primary-color);

            path{                
                stroke: var(--primary-color);
            }
        }
    }
}

.maxHeight{
    height: 100%;
}

.top_bottom{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.no_padding{
    padding: 0;

    .padded_bottom{
        padding-bottom: 15px;
        padding-right: 15px;
        padding-left: 15px;
    }
}

.drop_zone {
    text-align: center;
    padding: 25px;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    width:  100%;
    margin-bottom: 25px;
  }

.progress-bar{
     background-color: var(--primary-color);
}

.learningMaterialPageComponentContainer {
    .dragIcon {
        border-radius: 5px;
        padding: 2px;
        cursor: grab;
        transition: 1s all;
        opacity: 0;
    }

    .closeIcon {
        border-radius: 5px;
        padding: 2px;
        cursor: pointer;
        transition: 1s all;
        opacity: 0;
    }

    .editorContainer {
        background-color: var(--layout-card-background);
        padding-top: 5px;
    }

    .dragIcon:hover { background-color: var(--primary-color); color: white; }
    .closeIcon:hover { background-color: var(--primary-color); color: white; }
}
.learningMaterialPageComponentContainer:hover{
    .dragIcon { opacity: 1; }
    .closeIcon { opacity: 1; }
}

.mediaEditor{
    margin-bottom: 10px;
}

.inner-border-card1{
    border: 4px solid var(--card1);
}

.inner-border-primary {
    border: 4px solid var(--primary-color);
}

:focus { outline: none!important; }

.toggle_box{
    color: var(--card1-text);
    background-color: var(--card1);
    border-radius: 10px;

    svg
    {
        margin: auto;
        display: block;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .active
    {
        border-radius: 10px;
        background-color: var(--primary-color-hover);
        border-color: var(--primary-color-hover);
        color: white;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .non_active
    {
        border-radius: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .anim
    {
        transition: 0.5s all;
    }
}

.scale_svg
{
    svg
    {
        transition: 0.5s all;
    }
    :hover{
        svg{
            scale: 1.1;
        }
    }
}